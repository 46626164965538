<div id="job-matcher" class="section column center">
  <div class="section-panel gap-1">
    <div class="section-left center-vertical">
      <div id="job-matcher-image" class="section-image">
        <img src="assets/images/job-matcher-image.png" />
      </div>
    </div>
    <div
      id="job-matcher-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header
        mainText="Intelligent  Candidate Matching & Ranking"
        secondaryText="Precision Matching to Find the Ideal Candidate for Every Role"
      ></section-panel-header>

      <section-panel-content
        text="Hirefox's AI-driven platform allows you to effortlessly match candidate CVs to job positions,
          providing a 'fit score' that ranks each candidate based on their qualifications for the role.
          This innovative scoring system enables you to quickly identify the most suitable candidates,
          improving the quality of your hires and streamlining the recruitment process."
        [list]='[
          "AI Generated Candidate Evaluation score",
          "Compare Candidate To Average",
          "Candidates Ranked Based On “Match Score”"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product/job-matching",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.orange
        }'
      ></section-panel-button>
    </div>
  </div>
</div>
