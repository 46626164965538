import { Component } from '@angular/core';

@Component({
  selector: 'product-pipeline-collaboration',
  templateUrl: './product-pipeline-collaboration.component.html',
  styleUrls: ['./product-pipeline-collaboration.component.css']
})
export class ProductPipelineCollaborationComponent {

}
