<div id="product-resume-analysis-profiling" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="product-resume-analysis-profiling-image" class="section-image">
        <img src="assets/images/product-resume-analysis-insights.svg" />
      </div>
    </div>
    <div
      id="product-resume-analysis-profiling-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Personalized Insightful Indicators"
        secondaryText="At-A-Glance Evaluation: Red and Green Candidate Flags"
      ></section-panel-header-secondary>
      <div class="content-text">
        HireFox intelligently analyzes each applicant's
        CV according to your instructions to raise red or
        green flags, offering a powerful snapshot of
        potential concerns or commendable highlights.
        With this feature, you gain an immediate,
        visual understanding of each candidate's profile,
        helping you to quickly discern which applicants
        might require further review and which ones stand out
        as top contenders.
      </div>
    </div>
  </div>
</div>



