import { Component } from '@angular/core';
import {
  EButtonTarget, EButtonTheme,
  ESectionButtonType,
} from "../../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'overview-assistants',
  templateUrl: './overview-assistants.component.html',
  styleUrls: ['./overview-assistants.component.css']
})
export class OverviewAssistantsComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTarget = EButtonTarget;
  readonly EButtonTheme = EButtonTheme;
}
