<div id="landing" class="section center row">
  <div class="fill-space column center gap-5">
    <div class="fill-space column center">
      <div id="landing-title" class="row center gap-05">
        <div class="ai">AI</div>
        <div class="row-center-left">That Hires Like You.</div>
      </div>
      <div id="landing-subtitle" class="center">
        Delegate your hiring tasks to personalized AI Assistants that evaluate CVs and match candidates to jobs, precisely as you would.
      </div>
    </div>
    <div class="column center">
      <div id="landing-image">
        <img [src]="imageUrl" />
      </div>
    </div>
    <div id="landing-buttons" class="gap-1 wrap">
      <a [href]="urlService.register" target="_blank"
      (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_CREATE_ACCOUNT)">
        <div class="landing-button landing-button-orange gap-1 center">
          <div class="landing-button-icon">
            <img src="assets/images/create-account.svg" />
          </div>
          <div class="landing-button-text">
            Create An Account And Try It For Free!
          </div>
        </div>
      </a>
      <a [href]="urlService.calendar" target="_blank"
         (click)="gtmService.sendEvent(gtmService.EVENT_CLICKED_BOOK_DEMO)">
        <div class="landing-button landing-button-green gap-1 center">
          <div class="landing-button-icon">
            <img src="assets/images/book-demo.svg" />
          </div>
          <div class="landing-button-text">Book A Demo</div>
        </div>
      </a>
    </div>
  </div>
</div>
