<div *ngIf="sectionPanelButtonComponentProps.type === ESectionButtonType.link; else actionButton">
  <a
    *ngIf="this.urlService.isExternal(sectionPanelButtonComponentProps.url); else internalLink"
    [href]="sectionPanelButtonComponentProps.url"
    [target]="sectionPanelButtonComponentProps.target"
    (click)="this.sendGAEvent()"
  >
    <div [ngClass]="['section-panel-btn', sectionPanelButtonComponentProps.bgColor]">
      {{sectionPanelButtonComponentProps.text}}
    </div>
  </a>

  <ng-template #internalLink>
    <a
      [routerLink]="sectionPanelButtonComponentProps.url"
      [fragment]="sectionPanelButtonComponentProps.fragment"
      [target]="sectionPanelButtonComponentProps.target"
    >
      <div [ngClass]="['section-panel-btn', sectionPanelButtonComponentProps.bgColor]">
        {{sectionPanelButtonComponentProps.text}}
      </div>
    </a>
  </ng-template>
</div>

<ng-template #actionButton>
  <div
    *ngIf="sectionPanelButtonComponentProps.type === ESectionButtonType.action"
    [ngClass]="['section-panel-btn', sectionPanelButtonComponentProps.bgColor]"
    (click)="sectionPanelButtonComponentProps.buttonAction()"
  >
    {{sectionPanelButtonComponentProps.text}}
  </div>
</ng-template>
