import { Component } from '@angular/core';
import {EProductElementIds} from "../product.component";

@Component({
  selector: 'product-pipeline-page',
  templateUrl: './product-pipeline-page.component.html',
  styleUrls: ['./product-pipeline-page.component.css']
})
export class ProductPipelinePageComponent {
  readonly EProductElementIds = EProductElementIds;
}
