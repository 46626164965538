<div id="content-extraction" class="section column center">
  <div class="section-panel">
    <div
      id="content-extraction-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header
        mainText="CV Information Extraction Assistant"
        secondaryText="The content extraction assistant extracts every bit of information
          from your CVs in an accurate and reliable way."
      ></section-panel-header>

      <section-panel-content
        [list]='[
          "Process Different Types Of Files (PDF, Word, Image)",
          "Accurately Extract All Information From CVs",
          "Generate A ‘Quick Overview’ For Each Candidate Including All The Important Details You Want To See In A Glance",
          "Extract Key Information Such As Candidate Seniority, Job Tenure And Employment Gaps"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.grayGreenDark,
          fragment: EProductElementIds.resumeAnalysis
        }'
      ></section-panel-button>
    </div>
    <div class="section-right center-vertical">
      <div id="content-extraction-image" class="section-image">
        <img src="assets/images/ai-assistant-content-extraction.png" />
      </div>
    </div>
  </div>
</div>
