import { Component } from '@angular/core';

@Component({
  selector: 'product-database-filters',
  templateUrl: './product-database-filters.component.html',
  styleUrls: ['./product-database-filters.component.css']
})
export class ProductDatabaseFiltersComponent {

}
