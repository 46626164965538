import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  calendar: string = "https://calendly.com/hirefox-demo/hirefox";
  login: string = "https://app.hirefox.ai/login";
  register: string = "https://app.hirefox.ai/register";
  app: string = "https://app.hirefox.ai";
  email: string = "info@hirefox.ai";

  private readonly externalUrls: string[] = [];

  constructor() {
    this.externalUrls = [this.calendar, this.login, this.register, this.app];
  }

  isExternal(url: string): boolean {
    return this.externalUrls.includes(url);
  }
}
