import {Component, Input} from '@angular/core';

export interface IBackButtonComponentProps {
  url: string;
  text?: string;
  fragment?: string;
}

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent {
  @Input() url:string = "";
  @Input() text?:string;
  @Input() fragment?:string;

  @Input() backButtonComponentProps: IBackButtonComponentProps = {
    text: "",
    url: "",
  }
}
