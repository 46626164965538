import { Component } from '@angular/core';

@Component({
  selector: 'product-resume-analysis-insights',
  templateUrl: './product-resume-analysis-insights.component.html',
  styleUrls: ['./product-resume-analysis-insights.component.css']
})
export class ProductResumeAnalysisInsightsComponent {

}
