<div id="ai-assistants-personalized" class="section column center">
  <div class="section-panel gap-1">
    <div class="section-left center-vertical">
      <div id="ai-assistants-personalized-image" class="section-image">
        <img src="assets/images/ai-assistant-personalized.png" />
      </div>
    </div>
    <div
      id="ai-assistants-personalized-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header
        mainText="Personalized AI Assistants"
        secondaryText="Your Powerful Recruitment Allies."
      ></section-panel-header>

      <section-panel-content
        text="AI Assistants are built to help with different hiring tasks.
          They use information from CVs and your specific rules and guidelines
          to extract information, analyze it, and make decisions.
          For example, an assistant can pull out key information and insights from hundreds of CVs
          in just a few minutes, saving a lot of time compared to doing it manually."
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Try Hirefox For Free!",
          url: this.urlService.register,
          target: EButtonTarget.blank,
          bgColor: EButtonTheme.orange
        }'
      ></section-panel-button>
    </div>
  </div>
</div>

