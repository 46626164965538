<div id="product-database-search" class="section column center">
  <div class="section-panel gap-3">
    <div
      id="product-database-search-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Semantic Search"
        secondaryText="HireFox’s search is not relying on
          keywords but rather on the similarity of your
          search query to candidate CVs. For example if you
          misspell a keyword, the system will still retrieve
          the relevant results."
      ></section-panel-header-secondary>
    </div>
    <div class="section-right center-vertical">
      <div id="product-database-search-image" class="section-image">
        <img src="assets/images/product-database-search.svg" />
      </div>
    </div>
  </div>
</div>





