<a
  [routerLink]="url"
  [fragment]="fragment"
>
  <div class="back-button flex row gap-05">
    <div class="back-button-image center">
      <img src="assets/images/back-arrow-circled-grey-bg.svg" alt="back arrow">
    </div>
    <div class="back-button-image center">
      {{text ?? "Back"}}
    </div>
  </div>
</a>
