import { Component } from '@angular/core';

@Component({
  selector: 'product-job-matching-page-skills',
  templateUrl: './product-job-matching-page-skills.component.html',
  styleUrls: ['./product-job-matching-page-skills.component.css']
})
export class ProductJobMatchingPageSkillsComponent {

}
