import { Component } from '@angular/core';

@Component({
  selector: 'product-pipeline-reviews',
  templateUrl: './product-pipeline-reviews.component.html',
  styleUrls: ['./product-pipeline-reviews.component.css']
})
export class ProductPipelineReviewsComponent {

}
