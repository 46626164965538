import { Component } from '@angular/core';

@Component({
  selector: 'product-pipeline-main',
  templateUrl: './product-pipeline-main.component.html',
  styleUrls: ['./product-pipeline-main.component.css']
})
export class ProductPipelineMainComponent {

}
