import { Component } from '@angular/core';
import {
  EButtonTarget, EButtonTheme,
  ESectionButtonType,
} from "../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'product-pipeline',
  templateUrl: './product-pipeline.component.html',
  styleUrls: ['./product-pipeline.component.css']
})
export class ProductPipelineComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTarget = EButtonTarget;
  readonly EButtonTheme = EButtonTheme;
}
