<div id="footer-wrapper" class="fill-space">
  <div id="footer" class="fill-space column column-center-left">
    <div id="footer-top" class="">
      <div id="footer-logo"> <img src="assets/images/logo-white.svg"/> </div>
      <div class="fill-space"></div>
      <div class="center-vertical">
        <div id="footer-menu" class="center">
          <div class="footer-menu-item"
               *ngFor="let m of menu.pages"
               routerLinkActive="'footer-menu-item-selected'" [routerLink]="m.url"> {{m.name}} </div>
        </div>
      </div>
    </div>
    <div id="footer-bottom" class="column">
      <div class="fill-space"> © {{year}} Hirefox | All rights reserved.</div>
      <div id="footer-links" class="row">
        <div class="footer-link">
          <a routerLink="terms-of-service"> Terms of Service </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <div class="footer-link">
          <a routerLink="privacy-policy"> Privacy Policy </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <div class="footer-link">
          <a routerLink="cookie-policy"> Cookie Policy </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <div class="footer-link">
          <a routerLink="disclaimer"> Disclaimer </a>
        </div>
        <div class="footer-link-divider"> | </div>
        <a href="#" class="termly-display-preferences">Consent Preferences</a>
      </div>
    </div>
  </div>
</div>