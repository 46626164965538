import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BannerComponent } from './common/banner/banner.component';
import { LogoComponent } from './common/banner/logo/logo.component';
import { MenuComponent } from './common/banner/menu/menu.component';
import { HomeComponent } from './pages/home/home.component';
import { PageComponent } from './common/page/page.component';
import { Router } from '@angular/router';
import { LandingComponent } from './pages/home/landing/landing.component';
import { AboutComponent } from './pages/home/about/about.component';
import { BenchmarksComponent } from './pages/home/benchmarks/benchmarks.component';
import { AnalysisComponent } from './pages/home/analysis/analysis.component';
import { DatabaseComponent } from './pages/home/database/database.component';
import { JobMatcherComponent } from './pages/home/job-matcher/job-matcher.component';
import { LinkedinComponent } from './pages/home/linkedin/linkedin.component';
import { FooterComponent } from './common/footer/footer.component';
import { NewsletterComponent } from './pages/home/newsletter/newsletter.component';
import { GlanceComponent } from './pages/home/glance/glance.component';
import { FeaturesComponent } from './pages/features/features.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { CandidateAnalysisComponent } from './pages/features/candidate-analysis/candidate-analysis.component';
import { CandidateProfilingComponent } from './pages/features/candidate-profiling/candidate-profiling.component';
import { ResumeFormattingComponent } from './pages/features/resume-formatting/resume-formatting.component';
import { IndicatorsComponent } from './pages/features/indicators/indicators.component';
import { AttentionToDetailComponent } from './pages/features/attention-to-detail/attention-to-detail.component';
import { NewsletterSignUpComponent } from './common/newsletter-sign-up/newsletter-sign-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { LoaderComponent } from './common/loader/loader.component';
import { PipelineComponent } from './pages/home/pipeline/pipeline.component';
import { CtaButtonComponent } from './common/cta-button/cta-button.component';
import { DemoComponent } from './pages/demo/demo.component';
import * as Sentry from '@sentry/angular';
import { BenefitsComponent } from './pages/home/benefits/benefits.component';
import { SaveTimeAndMoneyComponent } from './pages/home/benefits/save-time-and-money/save-time-and-money.component';
import { MatSliderModule } from '@angular/material/slider';
import { HumanErrorComponent } from './pages/home/benefits/human-error/human-error.component';
import { UnbiasedEvaluationsComponent } from './pages/home/benefits/unbiased-evaluations/unbiased-evaluations.component';
import { ProductOverviewComponent } from './pages/home/product-overview/product-overview.component';
import { OverviewAssistantsComponent } from './pages/home/product-overview/overview-assistants/overview-assistants.component';
import { OverviewMatchingComponent } from './pages/home/product-overview/overview-matching/overview-matching.component';
import { OverviewAnalysisComponent } from './pages/home/product-overview/overview-analysis/overview-analysis.component';
import { OverviewRepositoryComponent } from './pages/home/product-overview/overview-repository/overview-repository.component';
import { OverviewReportsComponent } from './pages/home/product-overview/overview-reports/overview-reports.component';
import { OverviewPipelineComponent } from './pages/home/product-overview/overview-pipeline/overview-pipeline.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductResumeAnalysisComponent } from './pages/product/product-resume-analysis/product-resume-analysis.component';
import { ProductJobMatchingComponent } from './pages/product/product-job-matching/product-job-matching.component';
import { ProductDatabaseComponent } from './pages/product/product-database/product-database.component';
import { ProductPipelineComponent } from './pages/product/product-pipeline/product-pipeline.component';
import { HumanErrorDialogComponent } from './pages/home/benefits/human-error/human-error-dialog/human-error-dialog.component';
import { UnbiasedEvaluationDialogComponent } from './pages/home/benefits/unbiased-evaluations/unbiased-evaluation-dialog/unbiased-evaluation-dialog.component';
import { MobileMenuComponent } from './common/banner/mobile-menu/mobile-menu.component';
import { AiAssistantsComponent } from './pages/ai-assistants/ai-assistants.component';
import { AiAssistantsLandingComponent } from './pages/ai-assistants/ai-assistants-landing/ai-assistants-landing.component';
import { ContentExtractionAssistantComponent } from './pages/ai-assistants/content-extraction-assistant/content-extraction-assistant.component';
import { CvAnalysisAssistantComponent } from './pages/ai-assistants/cv-analysis-assistant/cv-analysis-assistant.component';
import { InterviewAssistantComponent } from './pages/ai-assistants/interview-assistant/interview-assistant.component';
import { JobMatchingAssistantComponent } from './pages/ai-assistants/job-matching-assistant/job-matching-assistant.component';
import { ListItemComponent } from './common/list-item/list-item.component';
import { PricingCardComponent } from './pages/pricing/pricing-card/pricing-card.component';
import { PersonalizedAiAssistantsComponent } from './pages/ai-assistants/personalized-ai-assistants/personalized-ai-assistants.component';
import { SectionPanelButtonComponent } from './common/section-panel-button/section-panel-button.component';
import { ProductPipelinePageComponent } from './pages/product/product-pipeline-page/product-pipeline-page.component';
import { ProductDatabasePageComponent } from './pages/product/product-database-page/product-database-page.component';
import { ProductResumeAnalysisPageComponent } from './pages/product/product-resume-analysis-page/product-resume-analysis-page.component';
import { ProductJobMatchingPageComponent } from './pages/product/product-job-matching-page/product-job-matching-page.component';
import { BackButtonComponent } from './common/back-button/back-button.component';
import { SectionPanelHeaderComponent } from './common/section-panel-header/section-panel-header.component';
import { SectionPanelContentComponent } from './common/section-panel-content/section-panel-content.component';
import { SectionPanelHeaderSecondaryComponent } from './common/section-panel-header-secondary/section-panel-header-secondary.component';
import { ProductJobMatchingPageRulesComponent } from './pages/product/product-job-matching-page/product-job-matching-page-rules/product-job-matching-page-rules.component';
import { ProductJobMatchingPageReportComponent } from './pages/product/product-job-matching-page/product-job-matching-page-report/product-job-matching-page-report.component';
import { ProductJobMatchingPageRequirementsComponent } from './pages/product/product-job-matching-page/product-job-matching-page-requirements/product-job-matching-page-requirements.component';
import { ProductJobMatchingPageSkillsComponent } from './pages/product/product-job-matching-page/product-job-matching-page-skills/product-job-matching-page-skills.component';
import { ProductJobMatchingPageConcernsComponent } from './pages/product/product-job-matching-page/product-job-matching-page-concerns/product-job-matching-page-concerns.component';
import { ProductResumeAnalysisProfilingComponent } from './pages/product/product-resume-analysis-page/product-resume-analysis-profiling/product-resume-analysis-profiling.component';
import { ProductResumeAnalysisFormattingComponent } from './pages/product/product-resume-analysis-page/product-resume-analysis-formatting/product-resume-analysis-formatting.component';
import { ProductResumeAnalysisInsightsComponent } from './pages/product/product-resume-analysis-page/product-resume-analysis-insights/product-resume-analysis-insights.component';
import { ProductDatabaseOrganizeComponent } from './pages/product/product-database-page/product-database-organize/product-database-organize.component';
import { ProductDatabaseFiltersComponent } from './pages/product/product-database-page/product-database-filters/product-database-filters.component';
import { ProductDatabaseSearchComponent } from './pages/product/product-database-page/product-database-search/product-database-search.component';
import { ProductPipelineMainComponent } from './pages/product/product-pipeline-page/product-pipeline-main/product-pipeline-main.component';
import { ProductPipelineNotesComponent } from './pages/product/product-pipeline-page/product-pipeline-notes/product-pipeline-notes.component';
import { ProductPipelineReviewsComponent } from './pages/product/product-pipeline-page/product-pipeline-reviews/product-pipeline-reviews.component';
import { ProductPipelineCollaborationComponent } from './pages/product/product-pipeline-page/product-pipeline-collaboration/product-pipeline-collaboration.component';
import { PrivacyPolicyComponent } from './pages/legal/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './pages/legal/cookie-policy/cookie-policy.component';
import { TermsOfServiceComponent } from './pages/legal/terms-of-service/terms-of-service.component';
import { DisclaimerComponent } from './pages/legal/disclaimer/disclaimer.component';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    LogoComponent,
    MenuComponent,
    HomeComponent,
    PageComponent,
    LandingComponent,
    AboutComponent,
    BenchmarksComponent,
    AnalysisComponent,
    DatabaseComponent,
    JobMatcherComponent,
    LinkedinComponent,
    FooterComponent,
    NewsletterComponent,
    GlanceComponent,
    FeaturesComponent,
    PricingComponent,
    CandidateAnalysisComponent,
    CandidateProfilingComponent,
    ResumeFormattingComponent,
    IndicatorsComponent,
    AttentionToDetailComponent,
    NewsletterSignUpComponent,
    LoaderComponent,
    PipelineComponent,
    CtaButtonComponent,
    DemoComponent,
    BenefitsComponent,
    SaveTimeAndMoneyComponent,
    HumanErrorComponent,
    UnbiasedEvaluationsComponent,
    ProductOverviewComponent,
    OverviewAssistantsComponent,
    OverviewMatchingComponent,
    OverviewAnalysisComponent,
    OverviewRepositoryComponent,
    OverviewReportsComponent,
    OverviewPipelineComponent,
    ProductComponent,
    ProductResumeAnalysisComponent,
    ProductJobMatchingComponent,
    ProductDatabaseComponent,
    ProductPipelineComponent,
    HumanErrorDialogComponent,
    UnbiasedEvaluationDialogComponent,
    MobileMenuComponent,
    AiAssistantsComponent,
    AiAssistantsLandingComponent,
    ContentExtractionAssistantComponent,
    CvAnalysisAssistantComponent,
    InterviewAssistantComponent,
    JobMatchingAssistantComponent,
    ListItemComponent,
    PricingCardComponent,
    PersonalizedAiAssistantsComponent,
    SectionPanelButtonComponent,
    ProductPipelinePageComponent,
    ProductDatabasePageComponent,
    ProductResumeAnalysisPageComponent,
    ProductJobMatchingPageComponent,
    BackButtonComponent,
    SectionPanelHeaderComponent,
    SectionPanelContentComponent,
    SectionPanelHeaderSecondaryComponent,
    ProductJobMatchingPageRulesComponent,
    ProductJobMatchingPageReportComponent,
    ProductJobMatchingPageRequirementsComponent,
    ProductJobMatchingPageSkillsComponent,
    ProductJobMatchingPageConcernsComponent,
    ProductResumeAnalysisProfilingComponent,
    ProductResumeAnalysisFormattingComponent,
    ProductResumeAnalysisInsightsComponent,
    ProductDatabaseOrganizeComponent,
    ProductDatabaseFiltersComponent,
    ProductDatabaseSearchComponent,
    ProductPipelineMainComponent,
    ProductPipelineNotesComponent,
    ProductPipelineReviewsComponent,
    ProductPipelineCollaborationComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    TermsOfServiceComponent,
    DisclaimerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSliderModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
