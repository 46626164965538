<div id="benefits" class="section column center">
  <div class="column center">
    <div class="title">Enhance Recruitment Efficiency</div>
    <div class="subtitle">
      Maximize Time Savings And Minimize Human Errors For A Streamlined Hiring Process.
    </div>
  </div>

  <save-time-and-money/>
  <human-error/>
  <unbiased-evaluations/>
</div>

