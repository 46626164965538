<div id="analysis" class="section column center">
  <div class="section-panel">
    <div
      id="analysis-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header
        mainText="Extract All Information From CVs And Get Personalized Insights"
        secondaryText="Say goodbye to manual CV screening!"
      ></section-panel-header>

      <section-panel-content
        text="HireFox effortlessly extracts all information from candidate CVs,
          regardless of whether they're in PDF, Doc, or image format and can generate personalized insights,
          based on your instructions. This enables you to identify the most qualified candidates quickly,
          without the hassle of time-consuming manual reviews."
        [list]='[
          "Extract All Information From CVs",
          "View All CVs Presented In A Uniform Format",
          "Generate Personalized Insights",
          "Process Hundreds Of CVs In Minutes"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product/resume-analysis",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.orange,
        }'
      ></section-panel-button>
    </div>
    <div class="section-right center-vertical">
      <div id="analysis-image" class="section-image">
        <img src="assets/images/resume-analysis.svg" />
      </div>
    </div>
  </div>
</div>
