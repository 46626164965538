<div class="section-panel center gap-5">
  <div class="section-panel-image"><img src="assets/images/overview-reports.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">Candidate Evaluation Reports</div>
    <div class="section-panel-content-text">
      Thorough, AI Generated reports for every candidate.
    </div>
    <section-panel-button
      [sectionPanelButtonComponentProps]="{
        type: ESectionButtonType.link,
        text: 'Find out more...',
        url: '/product',
        target: EButtonTarget.self,
        bgColor: EButtonTheme.orange
      }"
    ></section-panel-button>
  </div>
</div>
