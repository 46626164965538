<div id="human-error" class="section column center">
  <div class="section-panel center gap-5">
    <div class="section-left center">
      <div class="section-image">
        <img src="assets/images/bias.png" />
      </div>
    </div>
    <div class="section-left column column-center-left gap-2">
      <div class="section-title row row-center-left">
        Unbiased And Fair Evaluations
      </div>
      <div class="section-large-text">
        Ensure fair and impartial assessments of all candidates by eliminating
        human biases that can influence decision-making.
      </div>
      <section-panel-button
        [sectionPanelButtonComponentProps]="{
        type: ESectionButtonType.action,
        text: 'Read More...',
        bgColor: EButtonTheme.grayExtraLight,
        buttonAction: this.openDialog.bind(this)
      }"
      ></section-panel-button>
    </div>
  </div>
</div>
