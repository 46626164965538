import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object // Inject the PLATFORM_ID
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // Scroll to top on navigation end
      this.router.events.pipe(
          filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        window.scrollTo(0, 0);
      });
    }
  }
}
