import {Component, Input} from '@angular/core';

@Component({
  selector: 'section-panel-header-secondary',
  templateUrl: './section-panel-header-secondary.component.html',
  styleUrls: ['./section-panel-header-secondary.component.css']
})
export class SectionPanelHeaderSecondaryComponent {
  @Input() mainText: string = "";
  @Input() secondaryText: string = "";
}
