<div id="product-overview" class="section column center gap-5">
  <div class="column center">
    <div class="title">Hirefox At A Glance</div>
    <div class="subtitle">
      Your Recruitment Ally: Analyzing, Matching, and Organizing Made Simple.
    </div>
  </div>

  <div class="column gap-3">
    <overview-assistants></overview-assistants>
    <overview-matching></overview-matching>
    <overview-analysis></overview-analysis>
    <overview-repository></overview-repository>
    <overview-reports></overview-reports>
    <overview-pipeline></overview-pipeline>
  </div>

</div>


