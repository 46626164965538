import { Component } from '@angular/core';

@Component({
  selector: 'product-pipeline-notes',
  templateUrl: './product-pipeline-notes.component.html',
  styleUrls: ['./product-pipeline-notes.component.css']
})
export class ProductPipelineNotesComponent {

}
