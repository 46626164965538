<div id="product-resume-analysis-formatting" class="section column center">
  <div class="section-panel gap-3">
    <div
      id="product-resume-analysis-formatting-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Unified CV Formatting"
        secondaryText="Standardize CV Format for Quicker, Easier Review"
      ></section-panel-header-secondary>
      <div class="content-text">
        HireFox standardizes every CV into a consistent
        format, ensuring that the information you need
        is exactly where you expect it, every time.
        This uniformity allows for quicker comparisons
        and evaluations, freeing you from the
        frustration of inconsistent CV structures.
      </div>
    </div>
    <div class="section-right center-vertical">
      <div id="product-resume-analysis-formatting-image" class="section-image">
        <img src="assets/images/product-resume-analysis-formatting.svg" />
      </div>
    </div>
  </div>
</div>



