<div id="job-matching" class="section column center">
  <div class="section-panel">
    <div
      id="job-matching-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header
        mainText="Job Matching Assistant"
        secondaryText="The Job Matching assistant uses your own scoring instruction
          and rejection criteria to match candidates to jobs.
          The assistant evaluates candidates using your own scoring rules
          and creates a thorough evaluation report for each candidate."
      ></section-panel-header>

      <section-panel-content
        [list]='[
          "Match Candidates To Jobs Using Your Own Scoring Rules",
          "Auto-Reject Candidates Based On Your Own Rejection Criteria",
          "AI Generated, Candidate Evaluation Reports"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.grayGreenDark,
          fragment: EProductElementIds.jobMatching
        }'
      ></section-panel-button>
    </div>
    <div class="section-right center-vertical">
      <div id="job-matching-image" class="section-image">
        <img src="assets/images/ai-assistant-job-matching.png" />
      </div>
    </div>
  </div>
</div>
