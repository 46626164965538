<div id="interview" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="interview-image" class="section-image">
        <img src="assets/images/ai-assistant-interview.png" />
      </div>
    </div>
    <div
      id="interview-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header
        mainText="Interview Assistant"
        secondaryText="The Interview Assistant can schedule,
          prepare and perform asynchronous interviews with candidates.
          After each interview it reviews the candidate’s performance and creates a thorough evaluation report."
      ></section-panel-header>
      <section-panel-content
        [list]='[
          "Asynchronous Interview Scheduling",
          "Interview Preparation (Create Questions Based On Candidate’s CV And Job Requirements",
          "Asynchronous Interview Management",
          "Interview Evaluation & Report Generation"
        ]'
      ></section-panel-content>
    </div>
  </div>
</div>
