<div id="product" class="page column">
  <div class="column align-center">
    <div id="product-landing-title" class="center">
      All The Tools You Need To Streamline Hiring
    </div>
    <div id="product-landing-subtitle" class="center">
      Precision Matching to Find the Ideal Candidate for Every Role
    </div>
  </div>

  <product-job-matching [id]="JOB_MATCHING"/>
  <product-resume-analysis [id]="RESUME_ANALYSIS"/>
  <product-database [id]="PRODUCT_DATABASE"/>
  <product-pipeline [id]="PRODUCT_PIPELINE"/>
</div>
