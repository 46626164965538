import { Component } from '@angular/core';
import {
  EButtonTarget, EButtonTheme,
  ESectionButtonType,
} from "../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'product-resume-analysis',
  templateUrl: './product-resume-analysis.component.html',
  styleUrls: ['./product-resume-analysis.component.css']
})
export class ProductResumeAnalysisComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTarget = EButtonTarget;
  readonly EButtonTheme = EButtonTheme;
}
