import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { DemoComponent } from './pages/demo/demo.component';
import { ProductComponent } from './pages/product/product.component';
import { AiAssistantsComponent } from './pages/ai-assistants/ai-assistants.component';
import { ProductJobMatchingPageComponent } from './pages/product/product-job-matching-page/product-job-matching-page.component';
import { ProductResumeAnalysisPageComponent } from './pages/product/product-resume-analysis-page/product-resume-analysis-page.component';
import { ProductDatabasePageComponent } from './pages/product/product-database-page/product-database-page.component';
import { ProductPipelinePageComponent } from './pages/product/product-pipeline-page/product-pipeline-page.component';
import {SEO_DATA} from "./SEO_DATA";
import {seoResolver} from "./Resolvers/seo.resolver";
import {PrivacyPolicyComponent} from "./pages/legal/privacy-policy/privacy-policy.component";
import {TermsOfServiceComponent} from "./pages/legal/terms-of-service/terms-of-service.component";
import {CookiePolicyComponent} from "./pages/legal/cookie-policy/cookie-policy.component";
import {DisclaimerComponent} from "./pages/legal/disclaimer/disclaimer.component";

// Define routes
const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.home }
  },
  {
    path: 'home',
    component: HomeComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.home } // Reference SEO data for this route
  },
  {
    path: 'product',
    component: ProductComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.product }
  },
  {
    path: 'product/job-matching',
    component: ProductJobMatchingPageComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.jobMatching }
  },
  {
    path: 'product/resume-analysis',
    component: ProductResumeAnalysisPageComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.resumeAnalysis }
  },
  {
    path: 'product/database',
    component: ProductDatabasePageComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.database }
  },
  {
    path: 'product/pipeline',
    component: ProductPipelinePageComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.pipeline }
  },
  {
    path: 'pricing',
    component: PricingComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.pricing }
  },
  {
    path: 'demo',
    component: DemoComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.demo }
  },
  {
    path: 'ai-assistants',
    title: 'AI Assistants',
    component: AiAssistantsComponent,
    resolve: { seo: seoResolver },
    data: { seo: SEO_DATA.aiAssistants }
  },
    { path: 'terms-of-service', component: TermsOfServiceComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'cookie-policy', component: CookiePolicyComponent },
    { path: 'disclaimer', component: DisclaimerComponent },
  { path: '**', redirectTo: '/home' } // Redirect to home for invalid routes
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'disabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
