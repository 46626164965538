export enum EButtonTheme {
  orange = 'orange',
  grayGreenLight = 'gray-green-light',
  grayGreenDark = 'gray-green-dark',
  grayExtraLight = 'gray-extra-light',
}

export enum EButtonTarget {
  self = "_self",
  blank = "_blank",
  parent = "_parent",
  top = "_top"

}

export enum ESectionButtonType {
  action = "action",
  link = "link",
}

interface ISectionPanelButtonComponentLinkProps {
  type: ESectionButtonType.link,
  text: string;
  url: string;
  target: EButtonTarget;
  bgColor: EButtonTheme;
  fragment?: string;
}

interface ISectionPanelButtonComponentNoLinkProps {
  type: ESectionButtonType.action,
  text: string;
  bgColor: EButtonTheme;
  buttonAction: () => void;
}

export type ISectionPanelButtonComponentProps =
  ISectionPanelButtonComponentLinkProps
  | ISectionPanelButtonComponentNoLinkProps;
