<div id="product-database-page" class="page column gap-5">
  <div class="column align-center gap-1">
    <div id="product-database-page-title" class="center">
      CV Database
    </div>
    <div id="product-database-page-subtitle" class="center">
      Your Easily Searchable CV Repository
    </div>
    <div class="center">
      <back-button
        [url]="'/product'"
        [fragment]="EProductElementIds.productDatabase"
      ></back-button>
    </div>
  </div>

  <product-database-organize></product-database-organize>
  <product-database-filters></product-database-filters>
  <product-database-search></product-database-search>
</div>
