<div class="pricing-card column"  *ngIf="plan">
  <div class="pricing-card-title">
    {{ plan.type | titlecase }}
  </div>
  <div class="pricing-card-text">
    {{ plan.description }}
  </div>

  <div class="column">
    <div class="column center">
      <div class="credit-tile">Number of Credits</div>
      <div class="credit-options gap-05 wrap">
        <div class="credit-options-empty" *ngIf="plan.type === EPricingPlanType.CUSTOM"> Custom Amount </div>
        <div
          class="column center credit-number"
          *ngFor="let number of plan.creditOptions"
          (click)="selectPrice(number)"
          [ngClass]=" selectedNumber === number ? ['selected-credit-number', 'plan-' + plan.type] : [] "
        >
          {{ number }}
        </div>
      </div>
    </div>
  </div>
  <div class="column margin-top-auto gap-2">
    <div *ngIf="plan.pricePerCredit" class="price-text center">
      <div class="price">
        <div class="price-currency" [class]="'plan-'+plan.type+'-text'"> &euro; </div>
        {{ selectedNumber * plan.pricePerCredit - 1 }}
        <div class="price-period"> / year </div>
      </div>
    </div>
    <div *ngIf="plan.type === EPricingPlanType.CUSTOM" class="price-text center">Custom</div>

    <a [href]="getPricingLink()" target="_blank" class="button" [class]="'plan-'+plan.type">
      {{ plan.type === EPricingPlanType.CUSTOM ? 'Contact Us' : 'Get Started' }}
    </a>

    <div class="column gap-1">
      <div class="column-top-center gap-1">
        <div class="check center" [class]="'plan-'+plan.type">
          <img src="assets/images/check.svg" alt="check" />
        </div>
        <span class="features">All Features. No Restrictions!</span>
      </div>
      <div class="column-top-center gap-1">
        <div class="check center" [class]="'plan-'+plan.type">
          <img src="assets/images/check.svg" alt="check" />
        </div>
        <span class="features" *ngIf="plan.pricePerCredit">Parse, Analyze, Match up to {{ selectedNumber }} CVs </span>
        <span class="features" *ngIf="!plan.pricePerCredit">Choose a number of CVs that works for you</span>
      </div>
    </div>
  </div>
</div>
