import { Component } from '@angular/core';

@Component({
  selector: 'product-resume-analysis-formatting',
  templateUrl: './product-resume-analysis-formatting.component.html',
  styleUrls: ['./product-resume-analysis-formatting.component.css']
})
export class ProductResumeAnalysisFormattingComponent {

}
