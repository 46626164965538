import { Component } from '@angular/core';
import {EProductElementIds} from "../product.component";

@Component({
  selector: 'product-job-matching-page',
  templateUrl: './product-job-matching-page.component.html',
  styleUrls: ['./product-job-matching-page.component.css']
})
export class ProductJobMatchingPageComponent {
  readonly EProductElementIds = EProductElementIds;
}
