<div id="about" class="section column center">
  <div class="row gap-5 section-container">
    <div class="section-left center">
      <div id="about-image" class="section-image"> <img src="assets/images/about-image.png"/> </div>
    </div>
    <div id="about-content" class="section-right column column-center-left gap-3">
      <div class="column gap-1">
        <div class="section-title row-center-left">Work Smarter. Not Harder.</div>
        <div class="about-subtitle row-center-left">
          Delegate Hiring Tasks To AI Assistants So You Can Focus On More Meaningful Work.
        </div>
      </div>

      <div class="about-content-text">
        HireFox allows you to create and use AI-powered hiring assistants,
        designed to replicate your unique recruitment style.
        Assistants follow your evaluation criteria, scoring guidelines,
        and decision-making rules to match candidates to jobs and analyze CVs just like you would.
        With HireFox, you can delegate time-consuming, routine and repetitive tasks to AI,
        ensuring accuracy and consistency in every step,
        while you focus on strategic hiring decisions that matter.
      </div>
    </div>
  </div>
</div>

