<div id="product-pipeline-main" class="section column center">
  <div class="section-panel gap-5">
    <div
      id="product-pipeline-main-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Manage Your Hiring Pipeline"
        secondaryText="HireFox enables seamless management
          of your hiring pipeline by allowing users to move
          candidates between different stages effortlessly."
      ></section-panel-header-secondary>
    </div>
    <div class="section-right center-vertical">
      <div id="product-pipeline-main-image" class="section-image">
        <img src="assets/images/product-pipeline-main.svg" />
      </div>
    </div>
  </div>
</div>




