<div id="product-resume-analysis-page" class="page column gap-5">
  <div class="column align-center gap-1">
    <div id="product-resume-analysis-page-title" class="center">
      CV Analysis
    </div>
    <div id="product-resume-analysis-page-subtitle" class="center">
      Say Goodbye To Manual CV Screening!
    </div>
    <div class="center">
      <back-button
        [url]="'/product'"
        [fragment]="EProductElementIds.resumeAnalysis"
      ></back-button>
    </div>
  </div>

  <product-resume-analysis-profiling></product-resume-analysis-profiling>
  <product-resume-analysis-formatting></product-resume-analysis-formatting>
  <product-resume-analysis-insights></product-resume-analysis-insights>
</div>
