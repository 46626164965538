export const SEO_DATA = {
  home: {
    title: 'HireFox - AI That Hires Like You!',
    metaTags: [
      { name: 'description', content: 'Delegate your hiring tasks to personalized AI assistants that evaluate CVs and match candidates to jobs, precisely as you would.' },
      { property: 'og:title', content: 'HireFox - AI That Hires Like You!' },
      { property: 'og:description', content: 'Delegate your hiring tasks to personalized AI assistants that evaluate CVs and match candidates to jobs, precisely as you would.' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai' },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'HireFox - AI That Hires Like You!' },
      { name: 'twitter:description', content: 'Delegate your hiring tasks to personalized AI assistants.' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  product: {
    title: 'All The Tools You Need To Streamline Hiring',
    metaTags: [
      { name: 'description', content: 'Precision Matching to Find the Ideal Candidate for Every Role' },
      { property: 'og:title', content: 'All The Tools You Need To Streamline Hiring' },
      { property: 'og:description', content: 'Precision Matching to Find the Ideal Candidate for Every Role' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/product' },
      { property: 'og:type', content: 'product' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'All The Tools You Need To Streamline Hiring' },
      { name: 'twitter:description', content: 'Precision Matching to Find the Ideal Candidate for Every Role' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  jobMatching: {
    title: 'Job Matching',
    metaTags: [
      { name: 'description', content: 'Effectively Match Candidates To Jobs And Generate Thorough Evaluation Reports' },
      { property: 'og:title', content: 'Job Matching' },
      { property: 'og:description', content: 'Effectively Match Candidates To Jobs And Generate Thorough Evaluation Reports' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/product/job-matching' },
      { property: 'og:type', content: 'product' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Job Matching' },
      { name: 'twitter:description', content: 'Effectively Match Candidates To Jobs And Generate Thorough Evaluation Reports' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  resumeAnalysis: {
    title: 'CV Analysis',
    metaTags: [
      { name: 'description', content: 'Say Goodbye To Manual CV Screening!' },
      { property: 'og:title', content: 'CV Analysis' },
      { property: 'og:description', content: 'Say Goodbye To Manual CV Screening!' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/product/resume-analysis' },
      { property: 'og:type', content: 'product' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'CV Analysis' },
      { name: 'twitter:description', content: 'Say Goodbye To Manual CV Screening!' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  database: {
    title: 'CV Database',
    metaTags: [
      { name: 'description', content: 'Your Easily Searchable CV Repository' },
      { property: 'og:title', content: 'CV Database' },
      { property: 'og:description', content: 'Your Easily Searchable CV Repository' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/product/database' },
      { property: 'og:type', content: 'product' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'CV Database' },
      { name: 'twitter:description', content: 'Your Easily Searchable CV Repository' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  pipeline: {
    title: 'Track Candidate Progress',
    metaTags: [
      { name: 'description', content: 'Effortlessly Track And Enhance Candidate Progress' },
      { property: 'og:title', content: 'Track Candidate Progress' },
      { property: 'og:description', content: 'Effortlessly Track And Enhance Candidate Progress' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/product/pipeline' },
      { property: 'og:type', content: 'product' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Track Candidate Progress' },
      { name: 'twitter:description', content: 'Effortlessly Track And Enhance Candidate Progress' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  pricing: {
    title: 'Flexible Plans That Fit Every Need',
    metaTags: [
      { name: 'description', content: 'Start small and expand as you go' },
      { property: 'og:title', content: 'Flexible Plans That Fit Every Need' },
      { property: 'og:description', content: 'Start small and expand as you go' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/pricing' },
      { property: 'og:type', content: 'product:price' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Flexible Plans That Fit Every Need' },
      { name: 'twitter:description', content: 'Start small and expand as you go' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  demo: {
    title: 'HireFox Demo',
    metaTags: [
      { name: 'description', content: 'See a quick demo of the HireFox App' },
      { property: 'og:title', content: 'HireFox Demo' },
      { property: 'og:description', content: 'See a quick demo of the HireFox App' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/demo' },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'HireFox Demo' },
      { name: 'twitter:description', content: 'See a quick demo of the HireFox App' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  },
  aiAssistants: {
    title: 'AI Assistants For Modern HR',
    metaTags: [
      { name: 'description', content: 'Designed To Speed Up Processes And Enhance Productivity And Efficiency' },
      { property: 'og:title', content: 'AI Assistants For Modern HR' },
      { property: 'og:description', content: 'Designed To Speed Up Processes And Enhance Productivity And Efficiency' },
      { property: 'og:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { property: 'og:url', content: 'https://hirefox.ai/ai-assistants' },
      { property: 'og:type', content: 'website' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'AI Assistants For Modern HR' },
      { name: 'twitter:description', content: 'Designed To Speed Up Processes And Enhance Productivity And Efficiency' },
      { name: 'twitter:image', content: 'https://hirefox.ai/assets/images/og-image.png' },
      { name: 'twitter:site', content: '@HirefoxAi' }
    ]
  }
};
