<div class="section-panel center gap-5">
  <div class="section-panel-image"><img src="assets/images/overview-analysis.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">Analyze CVs And Extract Key Insights</div>
    <div class="section-panel-content-text">
      HireFox analyzes your CVs, extracts data and enhances them
      with valuable insights in the form of red/green flags.
    </div>
    <section-panel-button
      [sectionPanelButtonComponentProps]="{
        type: ESectionButtonType.link,
        text: 'Find out more...',
        url: '/product',
        target: EButtonTarget.self,
        bgColor: EButtonTheme.orange
      }"
    ></section-panel-button>
  </div>
</div>
