import {Component, HostListener} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NewsletterSignUpComponent} from "../../../common/newsletter-sign-up/newsletter-sign-up.component";
import {UrlService} from "../../../Services/url.service";
import {GoogleTagManagerService} from "../../../Services/google-tag-manager.service";

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  imageUrl: string = 'assets/images/landing-image.png';
  constructor(private dialog: MatDialog, public urlService: UrlService,  public gtmService: GoogleTagManagerService) {
  }

  showNewsletterSignUp(): void {
    this.dialog.open(NewsletterSignUpComponent, {});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateImage();
  }

  private updateImage(): void {
    this.imageUrl = window.innerWidth < 800
      ? 'assets/images/landing-image-mobile.png'
      : 'assets/images/landing-image.png';
  }

}
