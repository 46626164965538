import {Component, Input} from '@angular/core';
import {UrlService} from "../../Services/url.service";
import {GoogleTagManagerService} from "../../Services/google-tag-manager.service";
import {
  EButtonTarget,
  EButtonTheme,
  ESectionButtonType,
  ISectionPanelButtonComponentProps
} from "./types/section-panel-button-types";

@Component({
  selector: 'section-panel-button',
  templateUrl: './section-panel-button.component.html',
  styleUrls: ['./section-panel-button.component.css']
})

export class SectionPanelButtonComponent {

  @Input() sectionPanelButtonComponentProps: ISectionPanelButtonComponentProps = {
    type: ESectionButtonType.link,
    text: "",
    url: "",
    target: EButtonTarget.self,
    bgColor: EButtonTheme.orange
  };

  ESectionButtonType = ESectionButtonType;

  constructor(
    public urlService: UrlService,
    public gtmService: GoogleTagManagerService
  ) {}

  sendGAEvent(): void {
    if (this.sectionPanelButtonComponentProps.type === ESectionButtonType.link && this.urlService.isExternal(this.sectionPanelButtonComponentProps.url)) {
      this.sectionPanelButtonComponentProps.url === this.urlService.register && this.gtmService.sendEvent(this.gtmService.EVENT_CLICKED_CREATE_ACCOUNT);
      this.sectionPanelButtonComponentProps.url === this.urlService.login && this.gtmService.sendEvent(this.gtmService.EVENT_CLICKED_SIGN_IN);
      this.sectionPanelButtonComponentProps.url === this.urlService.calendar && this.gtmService.sendEvent(this.gtmService.EVENT_CLICKED_BOOK_DEMO);
    }
  }

}
