<div id="product-pipeline-reviews" class="section column center">
  <div class="section-panel gap-5">
    <div
      id="product-pipeline-reviews-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Add Candidate Reviews"
        secondaryText="Create candidate reviews by adding the
          pros and cons of each candidate, suggesting
          a score and leaving comments."
      ></section-panel-header-secondary>
    </div>
    <div class="section-right center-vertical">
      <div id="product-pipeline-reviews-image" class="section-image">
        <img src="assets/images/product-pipeline-reviews.svg" />
      </div>
    </div>
  </div>
</div>





