import { Component } from '@angular/core';

@Component({
  selector: 'product-job-matching-page-rules',
  templateUrl: './product-job-matching-page-rules.component.html',
  styleUrls: ['./product-job-matching-page-rules.component.css']
})
export class ProductJobMatchingPageRulesComponent {

}
