import { Component } from '@angular/core';
import {EProductElementIds} from "../product.component";

@Component({
  selector: 'product-database-page',
  templateUrl: './product-database-page.component.html',
  styleUrls: ['./product-database-page.component.css']
})
export class ProductDatabasePageComponent {
  readonly EProductElementIds = EProductElementIds;
}
