import {Component, Input} from '@angular/core';

@Component({
  selector: 'section-panel-header',
  templateUrl: './section-panel-header.component.html',
  styleUrls: ['./section-panel-header.component.css']
})
export class SectionPanelHeaderComponent {
  @Input() mainText: string = "";
  @Input() secondaryText: string = "";
  @Input() sectionTitleNote?: string;
}
