import {Component, Input} from '@angular/core';

@Component({
  selector: 'section-panel-content',
  templateUrl: './section-panel-content.component.html',
  styleUrls: ['./section-panel-content.component.css']
})
export class SectionPanelContentComponent {
  @Input() text?: string;
  @Input() list?: string[];
}
