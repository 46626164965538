<div id="product-job-matching-rules" class="section column center">
  <div class="section-panel gap-3">
    <div class="section-left center-vertical">
      <div id="product-job-matching-rules-image" class="section-image">
        <img src="assets/images/product-job-matching-rules.svg" />
      </div>
    </div>
    <div
      id="product-job-matching-rules-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Set up the rules and let AI do the rest!"
        secondaryText="The job matching process is simple:
          Define your scoring and rejection rules,
          and add applicants to the job. That’s it!
          AI takes over from there, providing you
          with a ranked list of candidates and a detailed
          evaluation report for each one."
      ></section-panel-header-secondary>
    </div>
  </div>
</div>

