import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ScrollService} from "../../Services/scroll.service";

export enum EProductElementIds {
  resumeAnalysis= "resume-analysis",
  jobMatching = "job-matching",
  productDatabase = "product-database",
  productPipeline = "product-pipeline"
}

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent {
  readonly RESUME_ANALYSIS= EProductElementIds.resumeAnalysis
  readonly JOB_MATCHING = EProductElementIds.jobMatching
  readonly PRODUCT_DATABASE = EProductElementIds.productDatabase
  readonly PRODUCT_PIPELINE = EProductElementIds.productPipeline

  constructor(
    private route: ActivatedRoute,
    private scrollService: ScrollService
  ) {}

  ngAfterViewInit() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.scrollService.scrollToFragment(fragment);
      }
    });
  }
}
