<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title">Match Candidates To Jobs</div>
    <div class="section-panel-content-text">
      The AI Assistant matches candidates to jobs using your own criteria and guidelines.
    </div>
    <section-panel-button
      [sectionPanelButtonComponentProps]="{
        type: ESectionButtonType.link,
        text: 'Find out more...',
        url: '/product',
        target: EButtonTarget.self,
        bgColor: EButtonTheme.orange
      }"
    ></section-panel-button>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-matching.png"/> </div>
</div>
