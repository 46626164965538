<div id="product-pipeline-page" class="page column gap-5">
  <div class="column align-center gap-1">
    <div id="product-pipeline-page-title" class="center">
      Track Candidate Progress
    </div>
    <div id="product-pipeline-page-subtitle" class="center">
      Effortlessly Track And Enhance Candidate Progress
    </div>
    <div class="center">
      <back-button
        [url]="'/product'"
        [fragment]="EProductElementIds.productPipeline"
      ></back-button>
    </div>
  </div>

  <product-pipeline-main></product-pipeline-main>
  <product-pipeline-notes></product-pipeline-notes>
  <product-pipeline-reviews></product-pipeline-reviews>
  <product-pipeline-collaboration></product-pipeline-collaboration>
</div>
