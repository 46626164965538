import {Component} from '@angular/core';
import {UrlService} from "../../../Services/url.service";
import {
  EButtonTarget, EButtonTheme,
  ESectionButtonType,
} from "../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'personalized-ai-assistants',
  templateUrl: './personalized-ai-assistants.component.html',
  styleUrls: ['./personalized-ai-assistants.component.css']
})
export class PersonalizedAiAssistantsComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTarget = EButtonTarget;
  readonly EButtonTheme = EButtonTheme;

  constructor(
    public urlService: UrlService,
  ) {}
}
