import { Component } from '@angular/core';

@Component({
  selector: 'product-job-matching-page-requirements',
  templateUrl: './product-job-matching-page-requirements.component.html',
  styleUrls: ['./product-job-matching-page-requirements.component.css']
})
export class ProductJobMatchingPageRequirementsComponent {
  readonly SECONDARY_TEXT: string = "HireFox scans job requirements and evaluates " +
  "whether candidates match them, offering reasons for each determination. " +
  "\nThe AI goes further, inferring skills from related activities " +
  "in the candidates’ professional history. " +
  "For instance, if a role requires strong organizational abilities " +
  "and a candidate has successfully managed large projects, " +
  "HireFox can infer and credit their organizational skills accordingly."
}
