<div id="product-job-matching-report" class="section column center">
  <div class="section-panel gap-5">
    <div
      id="product-job-matching-report-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header-secondary
        mainText="Candidate Evaluation Report"
        secondaryText="The Job Matching Assistant
          evaluates applicants across multiple dimensions—including experience,
          skills, education, and more— and for each section
          it provides a detailed comparison between the candidate
          and the average candidate."
      ></section-panel-header-secondary>
    </div>
    <div class="section-right center-vertical">
      <div id="product-job-matching-report-image" class="section-image">
        <img src="assets/images/product-job-matching-evaluation-report.svg" />
      </div>
    </div>
  </div>
</div>


