<div id="product-job-matching-page" class="page column gap-5">
  <div class="column align-center gap-1">
    <div id="product-job-matching-page-title" class="center">
      Job Matching
    </div>
    <div id="product-job-matching-page-subtitle" class="center">
      Effectively Match Candidates To Jobs And Generate Thorough Evaluation Reports
    </div>
    <div class="center">
      <back-button
        [url]="'/product'"
        [fragment]="EProductElementIds.jobMatching"
      ></back-button>
    </div>
  </div>

  <product-job-matching-page-rules></product-job-matching-page-rules>
  <product-job-matching-page-report></product-job-matching-page-report>
  <product-job-matching-page-requirements class="preserve-newlines"></product-job-matching-page-requirements>
  <product-job-matching-page-skills></product-job-matching-page-skills>
  <product-job-matching-page-concerns></product-job-matching-page-concerns>
</div>
