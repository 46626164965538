import {ResolveFn} from "@angular/router";
import {inject} from "@angular/core";
import {SeoService} from "../Services/seo.service";

export const seoResolver: ResolveFn<void> = (route) => {
  const seoService = inject(SeoService);
  const seoData = route.data['seo'];
  if (seoData) {
    seoService.updateTitle(seoData.title);
    seoService.updateMetaTags(seoData.metaTags);
  }
};
