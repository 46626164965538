<div id="section-panel-content" class="column column-center-left gap-2">
  <div
    *ngIf="!!text"
    class="section-panel-content-text"
  >
    {{text}}
  </div>
  <div
    *ngIf="!!list"
    class="column gap-1"
  >
    <list-item
      *ngFor="let listItem of list" [text]="listItem"
    ></list-item>
  </div>
</div>
