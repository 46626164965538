<div class="section-panel center gap-5">
  <div class="section-panel-content column">
    <div class="section-panel-content-title">Searchable CV Repository</div>
    <div class="section-panel-content-text">
      Create a database that stores all your CVs and allows you to search
      for any CV using advanced filtering capabilities.
    </div>
    <section-panel-button
      [sectionPanelButtonComponentProps]="{
        type: ESectionButtonType.link,
        text: 'Find out more...',
        url: '/product',
        target: EButtonTarget.self,
        bgColor: EButtonTheme.orange
      }"
    ></section-panel-button>
  </div>
  <div class="section-panel-image"><img src="assets/images/overview-repository.png"/> </div>
</div>
