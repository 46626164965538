import { Component } from '@angular/core';

@Component({
  selector: 'save-time-and-money',
  templateUrl: './save-time-and-money.component.html',
  styleUrls: ['./save-time-and-money.component.css'],
})
export class SaveTimeAndMoneyComponent {
  numberOfCvs: number = 120;
  numberOfMinutes: number = 5;
  totalTimeSaved: number = (this.numberOfCvs * this.numberOfMinutes) / 60;

  constructor() {
  }

  onChange(): void {
    this.totalTimeSaved =  Math.round((this.numberOfCvs * this.numberOfMinutes) / 60);
  }

  formatLabel(value: number): string {
    return `${value}`;
  }
}
