<div id="cv-analysis" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="cv-analysis-image" class="section-image">
        <img src="assets/images/ai-assistant-analysis.png" />
      </div>
    </div>
    <div
      id="cv-analysis-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header
        mainText="CV Analysis Assistant"
        secondaryText="The CV Analysis Assistant uses your instructions to generate insights
          about candidates based on the information in their CVs."
      ></section-panel-header>

      <section-panel-content
        [list]='[
          "Extract Personalized Insights Based On Your Input",
          "Create Meaningful Indicators To Quickly Identify Candidate Strength And Weaknesses",
          "Extract Custom Insights"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.grayGreenDark,
          fragment: EProductElementIds.resumeAnalysis
        }'
      ></section-panel-button>
    </div>
  </div>
</div>
