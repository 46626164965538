<div class="column column-center-left gap-05">
  <div id="title-header">
    {{ mainText }}
  </div>
  <div *ngIf="!!sectionTitleNote" class="section-title-note">{{sectionTitleNote}}</div>
  <div id="title-header-secondary" class="column-center-left">
    {{ secondaryText }}
  </div>
</div>

