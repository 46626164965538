<div id="database" class="section column center">
  <div class="section-panel gap-3">
    <div
      id="database-content"
      class="section-left column column-center-left gap-2"
    >
      <section-panel-header
        mainText="A Well Organized, Easily Searchable CV Database"
        secondaryText="Advanced CV Database Management With Powerful Filtering Capabilities"
      ></section-panel-header>

      <section-panel-content
        text="With Hirefox, you can quickly build a powerful, searchable database of candidate CVs.
          Simply upload your CV files, and our AI will parse and organize them for easy filtering and searching.
          Find the perfect candidate for any position in seconds with our user-friendly interface
          and advanced search capabilities."
        [list]='[
          "Seamless Search",
          "Advanced Filtering"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product/database",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.orange,
        }'
      ></section-panel-button>
    </div>
    <div class="section-right center-vertical">
      <div id="database-image" class="section-image">
        <img src="assets/images/database-image.svg" />
      </div>
    </div>
  </div>
</div>
