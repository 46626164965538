import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HumanErrorDialogComponent } from './human-error-dialog/human-error-dialog.component';
import {
  EButtonTheme,
  ESectionButtonType,
} from "../../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'human-error',
  templateUrl: './human-error.component.html',
  styleUrls: ['./human-error.component.css'],
})
export class HumanErrorComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTheme = EButtonTheme;

  constructor(private dialog: MatDialog) {

  }

  openDialog() {
    this.dialog.open(HumanErrorDialogComponent, {});
  }
}
