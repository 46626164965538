<div class="section-panel center gap-4">
  <div class="section-panel-image"><img src="assets/images/robot.png"/> </div>
  <div class="section-panel-content column">
    <div class="section-panel-content-title">Personalized  Hiring Assistants</div>
    <div class="section-panel-content-text">
      Create personalized AI hiring assistants using your own evaluation criteria,
      enabling them to replicate your hiring practices and decision-making style.
    </div>
    <section-panel-button
      [sectionPanelButtonComponentProps]="{
        type: ESectionButtonType.link,
        text: 'Find out more...',
        url: '/ai-assistants',
        target: EButtonTarget.self,
        bgColor: EButtonTheme.grayGreenLight
      }"
    ></section-panel-button>
  </div>
</div>
