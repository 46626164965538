import { Component } from '@angular/core';
import {EProductElementIds} from "../../product/product.component";
import {
  EButtonTarget, EButtonTheme,
  ESectionButtonType,
} from "../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'content-extraction-assistant',
  templateUrl: './content-extraction-assistant.component.html',
  styleUrls: ['./content-extraction-assistant.component.css']
})
export class ContentExtractionAssistantComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTarget = EButtonTarget;
  readonly EButtonTheme = EButtonTheme;
  readonly EProductElementIds = EProductElementIds;
}
