<div id="pipeline" class="section column center">
  <div class="section-panel gap-5">
    <div class="section-left center-vertical">
      <div id="pipeline-image" class="section-image">
        <img src="assets/images/pipeline.png" />
      </div>
    </div>
    <div
      id="pipeline-content"
      class="section-right column column-center-left gap-2"
    >
      <section-panel-header
        mainText="Manage Your Hiring Pipeline"
        secondaryText="Effortlessly Track And Enhance Candidate's Progress"
      ></section-panel-header>

      <section-panel-content
        text="HireFox enables seamless management of your hiring pipeline
          by allowing users to move candidates between different stages effortlessly.
          Each applicant can be commented on and rated, facilitating collaborative and informed decision-making
          within your recruitment team."
        [list]='[
          "Hiring Pipeline",
          "Keep Notes For Every Candidate",
          "Add Candidate Reviews"
        ]'
      ></section-panel-content>
      <section-panel-button
        [sectionPanelButtonComponentProps]='{
          type: ESectionButtonType.link,
          text: "Read More...",
          url: "/product/pipeline",
          target: EButtonTarget.self,
          bgColor: EButtonTheme.orange
        }'
      ></section-panel-button>
    </div>
  </div>
</div>
