import {Component} from '@angular/core';
import {EProductElementIds} from "../product.component";

@Component({
  selector: 'product-resume-analysis-page',
  templateUrl: './product-resume-analysis-page.component.html',
  styleUrls: ['./product-resume-analysis-page.component.css']
})
export class ProductResumeAnalysisPageComponent {
  readonly EProductElementIds = EProductElementIds;
}
