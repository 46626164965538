import { Component } from '@angular/core';
import {
  EButtonTarget, EButtonTheme,
  ESectionButtonType,
} from "../../../../common/section-panel-button/types/section-panel-button-types";

@Component({
  selector: 'overview-pipeline',
  templateUrl: './overview-pipeline.component.html',
  styleUrls: ['./overview-pipeline.component.css']
})
export class OverviewPipelineComponent {
  readonly ESectionButtonType = ESectionButtonType;
  readonly EButtonTarget = EButtonTarget;
  readonly EButtonTheme = EButtonTheme;
}
