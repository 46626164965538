import { Component } from '@angular/core';

@Component({
  selector: 'product-job-matching-page-report',
  templateUrl: './product-job-matching-page-report.component.html',
  styleUrls: ['./product-job-matching-page-report.component.css']
})
export class ProductJobMatchingPageReportComponent {

}
